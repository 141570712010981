// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-footer.copyright p {
  margin: 0;
  text-align: left;
}
.dashboard-footer.copyright ul {
  display: flex;
  justify-content: flex-end;
}
/* .dashboard-footer.copyright ul li a {
  color: var(--Is-white);
  margin-left: 30px;
}
.dashboard-footer.copyright ul {
  display: flex;
  justify-content: flex-end;
}
.dashboard-footer.copyright ul li a:hover {
  color: var(--Is-yellow);
} */
.dashboard-footer.copyright {
  position: absolute;
  left: 230px;
  width: calc(100% - 230px);
  padding: 20px 25px;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Footer/footer.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;;;;;;;;;;GAUG;AACH;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".dashboard-footer.copyright p {\n  margin: 0;\n  text-align: left;\n}\n.dashboard-footer.copyright ul {\n  display: flex;\n  justify-content: flex-end;\n}\n/* .dashboard-footer.copyright ul li a {\n  color: var(--Is-white);\n  margin-left: 30px;\n}\n.dashboard-footer.copyright ul {\n  display: flex;\n  justify-content: flex-end;\n}\n.dashboard-footer.copyright ul li a:hover {\n  color: var(--Is-yellow);\n} */\n.dashboard-footer.copyright {\n  position: absolute;\n  left: 230px;\n  width: calc(100% - 230px);\n  padding: 20px 25px;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
