import React from "react";

export default function ApplicationIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="my_application customSvG"
    >
      <g clipPath="url(#clip0_408_11992)">
        <path
          d="M2.89844 15.7519H11.1016C11.7801 15.7519 12.332 15.1634 12.332 14.44V5.19809H9.46094C8.78243 5.19809 8.23047 4.60958 8.23047 3.88615V0.824951H2.89844C2.21993 0.824951 1.66797 1.41346 1.66797 2.13689V14.44C1.66797 15.1634 2.21993 15.7519 2.89844 15.7519ZM4.53906 6.9765H9.46094C9.68764 6.9765 9.87109 7.17209 9.87109 7.41381C9.87109 7.65553 9.68764 7.85113 9.46094 7.85113H4.53906C4.31236 7.85113 4.12891 7.65553 4.12891 7.41381C4.12891 7.17209 4.31236 6.9765 4.53906 6.9765ZM4.53906 8.72575H9.46094C9.68764 8.72575 9.87109 8.92135 9.87109 9.16307C9.87109 9.40478 9.68764 9.60038 9.46094 9.60038H4.53906C4.31236 9.60038 4.12891 9.40478 4.12891 9.16307C4.12891 8.92135 4.31236 8.72575 4.53906 8.72575ZM4.53906 10.475H9.46094C9.68764 10.475 9.87109 10.6706 9.87109 10.9123C9.87109 11.154 9.68764 11.3496 9.46094 11.3496H4.53906C4.31236 11.3496 4.12891 11.154 4.12891 10.9123C4.12891 10.6706 4.31236 10.475 4.53906 10.475ZM4.53906 12.2243H7.82031C8.04702 12.2243 8.23047 12.4199 8.23047 12.6616C8.23047 12.9033 8.04702 13.0989 7.82031 13.0989H4.53906C4.31236 13.0989 4.12891 12.9033 4.12891 12.6616C4.12891 12.4199 4.31236 12.2243 4.53906 12.2243Z"
          fill="#646464"
        />
        <path
          d="M9.46094 4.32357H12.0917L9.05078 1.0813V3.88626C9.05078 4.12754 9.23464 4.32357 9.46094 4.32357Z"
          fill="#646464"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_11992">
          <rect
            width="14"
            height="14.927"
            fill="white"
            transform="translate(0 0.824951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
