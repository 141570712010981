import React from "react";

export default function UserIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="my_profile customSvG"
    >
      <g clipPath="url(#clip0_408_12004)">
        <path
          d="M6.90036 7.539C7.82684 7.539 8.6291 7.18471 9.28461 6.48569C9.94011 5.78679 10.2724 4.93164 10.2724 3.9437C10.2724 2.95611 9.94011 2.10084 9.2845 1.40171C8.62889 0.702924 7.82674 0.348633 6.90036 0.348633C5.97377 0.348633 5.17172 0.702924 4.51622 1.40183C3.86072 2.10073 3.52832 2.95599 3.52832 3.9437C3.52832 4.93164 3.86072 5.7869 4.51633 6.48581C5.17194 7.18459 5.97409 7.539 6.90036 7.539Z"
          fill="#646464"
        />
        <path
          d="M12.8004 11.8266C12.7815 11.5358 12.7433 11.2185 12.687 10.8834C12.6301 10.5459 12.557 10.2268 12.4694 9.93513C12.3789 9.63368 12.2559 9.33599 12.1038 9.05071C11.9459 8.75461 11.7605 8.49678 11.5524 8.28462C11.3348 8.06266 11.0685 7.8842 10.7604 7.75403C10.4534 7.62455 10.1132 7.55895 9.74933 7.55895C9.60641 7.55895 9.4682 7.62147 9.20128 7.80676C9.037 7.92099 8.84485 8.05309 8.63037 8.1992C8.44698 8.32379 8.19853 8.44052 7.89166 8.54621C7.59227 8.6495 7.28828 8.70188 6.98825 8.70188C6.68822 8.70188 6.38434 8.6495 6.08463 8.54621C5.77808 8.44064 5.52963 8.32391 5.34645 8.19932C5.134 8.05457 4.94174 7.92247 4.77501 7.80665C4.50841 7.62136 4.37009 7.55884 4.22717 7.55884C3.86316 7.55884 3.52307 7.62455 3.2162 7.75415C2.90837 7.88409 2.64188 8.06254 2.42409 8.28473C2.21613 8.49701 2.03059 8.75473 1.87294 9.05071C1.72095 9.33599 1.5979 9.63356 1.50732 9.93524C1.41985 10.2269 1.34668 10.5459 1.28986 10.8834C1.23357 11.218 1.19533 11.5354 1.17642 11.827C1.15784 12.1126 1.14844 12.409 1.14844 12.7084C1.14844 13.4876 1.38075 14.1184 1.83887 14.5836C2.29132 15.0427 2.89 15.2756 3.61803 15.2756H10.3591C11.0871 15.2756 11.6856 15.0428 12.1382 14.5836C12.5964 14.1187 12.8287 13.4878 12.8287 12.7083C12.8286 12.4075 12.8191 12.1109 12.8004 11.8266Z"
          fill="#646464"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_12004">
          <rect
            width="14"
            height="14.927"
            fill="white"
            transform="translate(0 0.348633)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
