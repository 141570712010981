// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Signup/Signup.css */
.signupContainer {
  max-width: 400px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* RegistrationPage.css */
.registrationPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.outerBox {
  width: 600px;
}

.registrationPageContent {
  padding: 20px;
}

.roleCheckboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.roleCard {
  width: calc(33.333% - 20px); /* Adjust as needed */
  flex: 1 1 auto;
}

.selectedRoleContainer {
  margin-top: 20px;
}

.selectedRoleContainer h3 {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Signup/SignUp.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uCAAuC;EACvC,sBAAsB;AACxB;;AAEA,yBAAyB;AACzB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,2BAA2B,EAAE,qBAAqB;EAClD,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* src/components/Signup/Signup.css */\n.signupContainer {\n  max-width: 400px;\n  margin: auto;\n  margin-top: 20px;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  background-color: #fff;\n}\n\n/* RegistrationPage.css */\n.registrationPageContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 50px;\n}\n\n.outerBox {\n  width: 600px;\n}\n\n.registrationPageContent {\n  padding: 20px;\n}\n\n.roleCheckboxGroup {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n\n.roleCard {\n  width: calc(33.333% - 20px); /* Adjust as needed */\n  flex: 1 1 auto;\n}\n\n.selectedRoleContainer {\n  margin-top: 20px;\n}\n\n.selectedRoleContainer h3 {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
