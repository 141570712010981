export const JOBS = [
    {
      id: 1,
      title: "Western Family with baby looking for helper",
      description: "Description for Job Title 1.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_5.png",
    },
    {
      id: 2,
      title: "Looking for local Filipino helper",
      description: "Description for Job Title 2.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_6.png",
    },
    {
      id: 3,
      title: "Australian Family -looking for helper",
      description: "Description for Job Title 3.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_7.png",
    },
    {
      id: 4,
      title: "Lebanese family searching for a nanny / housekeeper",
      description: "Description for Job Title 4.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_5.png",
    },
    {
      id: 5,
      title: "Helper in Warm and Happy Home with expectations",
      description: "Description for Job Title 5.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_6.png",
    },
    {
      id: 6,
      title: "Chinese Family with New Born Looking for Helper",
      description: "Description for Job Title 6.",
      location: "Hong Kong",
      date: "From 27 Feb 2024",
      availability: "Full Time",
      image: "image_7.png",
    },
  ];
