import React, { useState } from "react";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ThreeDotsSvgIcon from "../../../ThreeDotsSvgIcon";
import { useLocation, useNavigate } from "react-router-dom";

const MyPostedJobCard = ({ jobDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedRole = localStorage.getItem("selectedRole");
  console.log(selectedRole,"selectedRole")

  return (
    // <Box className="jobPost">
    //   <Box className="profileRole">
    //     <Typography variant="h6">DOMESTIC HELP1</Typography>
    //     {(selectedRole === "agency" || selectedRole === "employer") && (
    //       <>
    //         <div
    //           id="basic-button"
    //           className="cursor-pointer three-dot-icon"
    //           aria-controls={showMenu ? "basic-menu" : undefined}
    //           aria-haspopup="true"
    //           aria-expanded={showMenu ? "true" : undefined}
    //           onClick={(e) => {
    //             setShowMenu((prev) => true);
    //             setAnchorEl(e.currentTarget);
    //           }}
    //         >
    //           <ThreeDotsSvgIcon />
    //         </div>
    //         <Menu
    //           id="basic-menu"
    //           anchorEl={anchorEl}
    //           open={showMenu}
    //           onClose={() => setShowMenu((prev) => false)}
    //           MenuListProps={{
    //             "aria-labelledby": "basic-button",
    //           }}
    //         >
    //           <MenuItem
    //             onClick={() => {
    //               selectedRole === "agency"
    //                 ? navigate(`/agency/job-details/${jobDetails?._id}`)
    //                 : selectedRole === "employer" &&
    //                   navigate("/employer/my-job-post");
    //             }}
    //           >
    //             {t("view")}
    //           </MenuItem>
    //           <MenuItem
    //             onClick={() => {
    //               selectedRole==="agency"
    //                 ? navigate(`/agency/job-post/${jobDetails?._id}`)
    //                 : selectedRole === "employer" &&
    //                   navigate("/employer/job-post");
    //             }}
    //           >
    //             {t("edit")}
    //           </MenuItem>
    //           {/* <MenuItem>{t("reactivate")}</MenuItem> */}
    //         </Menu>
    //       </>
    //     )}
    //     {/* <div>
    //       <IconButton
    //         aria-label="more"
    //         id="long-button"
    //         aria-controls={open ? "long-menu" : undefined}
    //         aria-expanded={open ? "true" : undefined}
    //         aria-haspopup="true"
    //         onClick={handleClick}
    //       >
    //         <MoreVertIcon />
    //       </IconButton>
    //       <Menu
    //         id="long-menu"
    //         MenuListProps={{
    //           "aria-labelledby": "long-button",
    //         }}
    //         anchorEl={anchorEl}
    //         open={open}
    //         onClose={handleClose}
    //         PaperProps={{
    //           style: {
    //             maxHeight: ITEM_HEIGHT * 4.5,
    //             width: "20ch",
    //           },
    //         }}
    //       >
    //         {options.map((option) => (
    //           <MenuItem
    //             key={option}
    //             selected={option === "Pyxis"}
    //             onClick={handleClose}
    //           >
    //             {option}
    //           </MenuItem>
    //         ))}
    //       </Menu>
    //     </div> */}
    //   </Box>
    //   {/* <Box className="profileInner">
   
    //     <Box>
    //       <Typography className="job_title">
    //         {jobDetails.jobDetails?.jobTitle}
    //       </Typography>
    //       <Typography className="status active">
    //         {jobDetails.jobStatus}
    //       </Typography>
    //     </Box>
    //     <Typography className="job_desc">
    //       {jobDetails.jobDetails?.jobDescription}
    //     </Typography>
    //   </Box> */}
    //   {/* <Box className="profileInsights">
    //     <Typography className="job_title">
    //       <span>{jobDetails.applicants?.length}</span> New Application{" "}
    //     </Typography>
    //     <Typography className="job_desc">
    //       <span>0</span> Conversation
    //     </Typography>
    //   </Box> */}
  
    // </Box>
    <>      
     <div className="col-md-6 col-xl-12">
  
    <a href="apps/projects/project.html" className="card border-hover-primary" style={{textDecoration: 'none'}}>
  
      <div className="card-header border-0 pt-9">
   
        <div className="card-title m-0">

        <Typography variant="h6">DOMESTIC HELP1</Typography>
        
        </div>
   
        <div className="card-toolbar">
          <span className="status active">{jobDetails.jobStatus}</span>
        </div>
      
      </div>
  
      <div className="card-body p-9">
  
        <div className="fs-3 fw-bold text-gray-900">{jobDetails.jobDetails?.jobTitle}</div>
  
        <p className="text-gray-500 fw-semibold fs-5 mt-1 mb-7">
        {jobDetails.jobStatus}
        </p>

        <div className="fs-3"> {jobDetails.jobDetails?.jobDescription}</div>
        <div className="d-flex flex-wrap mb-5">
      
          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
            <div className="fs-6 text-gray-800 fw-bold"><span>{jobDetails.applicants?.length}</span> </div>
            <div className="fw-semibold text-gray-500">New Application{" "}</div>
          </div>
    
          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
            <div className="fs-6 text-gray-800 fw-bold"><span>0</span> </div>
            <div className="fw-semibold text-gray-500">Conversation</div>
          </div>
       
        </div>
  
        {/* <div
          className="h-4px w-100 bg-light mb-5"
          data-bs-toggle="tooltip"
          title="This project 50% completed"
        >
          <div
            className="bg-primary rounded h-4px"
            role="progressbar"
            style={{ width: '50%' }}
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
    */}
        {/* <div className="symbol-group symbol-hover">
    
          <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
            <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
          </div>
   
          <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
            <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
          </div>
  
          <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
            <span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
          </div>
        
        </div> */}
    
      </div>
  
    </a>
  
  </div></>
  );
};

export default MyPostedJobCard;
