import React from "react";

const EmployerSvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10 9.89062C10.9946 9.89062 11.9484 10.2857 12.6517 10.989C13.3549 11.6922 13.75 12.6461 13.75 13.6406V14.3906C13.75 14.7884 13.592 15.17 13.3107 15.4513C13.0294 15.7326 12.6478 15.8906 12.25 15.8906H1.75C1.35218 15.8906 0.970644 15.7326 0.68934 15.4513C0.408035 15.17 0.25 14.7884 0.25 14.3906V13.6406C0.25 12.6461 0.645088 11.6922 1.34835 10.989C2.05161 10.2857 3.00544 9.89062 4 9.89062H10ZM13 5.39062C13.1989 5.39062 13.3897 5.46964 13.5303 5.6103C13.671 5.75095 13.75 5.94171 13.75 6.14062V6.89062H14.5C14.6989 6.89062 14.8897 6.96964 15.0303 7.1103C15.171 7.25095 15.25 7.44171 15.25 7.64062C15.25 7.83954 15.171 8.0303 15.0303 8.17095C14.8897 8.31161 14.6989 8.39062 14.5 8.39062H13.75V9.14062C13.75 9.33954 13.671 9.5303 13.5303 9.67095C13.3897 9.81161 13.1989 9.89062 13 9.89062C12.8011 9.89062 12.6103 9.81161 12.4697 9.67095C12.329 9.5303 12.25 9.33954 12.25 9.14062V8.39062H11.5C11.3011 8.39062 11.1103 8.31161 10.9697 8.17095C10.829 8.0303 10.75 7.83954 10.75 7.64062C10.75 7.44171 10.829 7.25095 10.9697 7.1103C11.1103 6.96964 11.3011 6.89062 11.5 6.89062H12.25V6.14062C12.25 5.94171 12.329 5.75095 12.4697 5.6103C12.6103 5.46964 12.8011 5.39062 13 5.39062ZM7 0.890625C7.99456 0.890625 8.94839 1.28571 9.65165 1.98897C10.3549 2.69224 10.75 3.64606 10.75 4.64062C10.75 5.63519 10.3549 6.58901 9.65165 7.29228C8.94839 7.99554 7.99456 8.39062 7 8.39062C6.00544 8.39062 5.05161 7.99554 4.34835 7.29228C3.64509 6.58901 3.25 5.63519 3.25 4.64062C3.25 3.64606 3.64509 2.69224 4.34835 1.98897C5.05161 1.28571 6.00544 0.890625 7 0.890625Z"
        fill="#646464"
      />
    </svg>
  );
};

export default EmployerSvgIcon;
