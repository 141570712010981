import React from "react";

export default function NotificationBellIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="notifications customSvG"
    >
      <path
        d="M11.9714 10.9242C11.0894 10.1416 10.5837 8.99775 10.5837 7.78589V6.07863C10.5837 3.92373 9.05883 2.13992 7.08366 1.84109V1.17974C7.08366 0.841108 6.82233 0.567383 6.50033 0.567383C6.17833 0.567383 5.91699 0.841108 5.91699 1.17974V1.84109C3.94124 2.13992 2.41699 3.92373 2.41699 6.07863V7.78589C2.41699 8.99775 1.91124 10.1416 1.02399 10.9291C0.797076 11.133 0.666992 11.4294 0.666992 11.743C0.666992 12.3339 1.12491 12.8146 1.68783 12.8146H11.3128C11.8757 12.8146 12.3337 12.3339 12.3337 11.743C12.3337 11.4294 12.2036 11.133 11.9714 10.9242Z"
        fill="#646464"
      />
    </svg>
  );
}
