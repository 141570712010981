import React from "react";

export default function SubscribePublishSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 0C19.142 0 22.892 1.6793 25.6066 4.39336C28.3207 7.10742 30 10.858 30 15C30 19.142 28.3207 22.892 25.6066 25.6066C22.892 28.3213 19.142 30 15 30C10.858 30 7.10742 28.3213 4.39336 25.6066C1.6793 22.892 0 19.142 0 15C0 10.858 1.6793 7.10742 4.39336 4.39336C7.10742 1.6793 10.858 0 15 0ZM9.7541 13.885C10.8088 14.492 11.4943 14.9977 12.3094 15.8971C14.4293 12.4875 16.7291 10.5955 19.7203 7.91309L20.0121 7.80117H23.2846C18.8965 12.6756 15.4951 16.6928 12.4471 22.5715C10.8621 19.1777 9.44766 16.8387 6.28535 14.6689L9.7541 13.885ZM24.1049 5.89512C21.7746 3.56543 18.5555 2.12402 15 2.12402C11.4439 2.12402 8.2248 3.56543 5.89512 5.89512C3.56543 8.22539 2.12402 11.4439 2.12402 15C2.12402 18.5555 3.56543 21.7746 5.89512 24.1049C8.2248 26.4346 11.4439 27.876 15 27.876C18.5555 27.876 21.7746 26.4346 24.1049 24.1049C26.4346 21.7746 27.876 18.5555 27.876 15C27.876 11.4439 26.4346 8.22539 24.1049 5.89512Z"
        fill="#646464"
      />
    </svg>
  );
}
