// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complete-profile-right {
    max-width: 500px;
    width: 100%;
}

.complete-profile-right .progress {
    border-radius: 50px;
    background: #ddd;
    margin-bottom: 6px;
}

.progress-bar {
    background: #0A6259 !important;
    border-radius: 50px !important;
}

.complete-profile-right p {
    font-size: 18px !important;
}

.complete-profile-right span {
    font-size: 22px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Headers/HeaderStyle.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".complete-profile-right {\n    max-width: 500px;\n    width: 100%;\n}\n\n.complete-profile-right .progress {\n    border-radius: 50px;\n    background: #ddd;\n    margin-bottom: 6px;\n}\n\n.progress-bar {\n    background: #0A6259 !important;\n    border-radius: 50px !important;\n}\n\n.complete-profile-right p {\n    font-size: 18px !important;\n}\n\n.complete-profile-right span {\n    font-size: 22px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
