import React from "react";

const SubscriptionPlanSvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M14 2V1H12.5V0H11.5V1H11C10.4485 1 10 1.449 10 2V3C10 3.5515 10.4485 4 11 4H13V5H10V6H11.5V7H12.5V6H13C13.5515 6 14 5.5515 14 5V4C14 3.449 13.5515 3 13 3H11V2H14ZM11 9V10H12.293L10.5 11.793L9.354 10.6465C9.26038 10.5528 9.13342 10.5002 9.001 10.5H9C8.93442 10.5 8.86948 10.5129 8.8089 10.5381C8.74833 10.5632 8.69331 10.6001 8.647 10.6465L6 13.293L6.707 14L9.0005 11.707L10.1465 12.8535C10.2403 12.9472 10.3674 12.9999 10.5 12.9999C10.6326 12.9999 10.7597 12.9472 10.8535 12.8535L13 10.707V12H14V9H11ZM1 14H0V11.5C0 9.57 1.57 8 3.5 8H6.5C7.4945 8 8.445 8.425 9.1085 9.1665L8.3635 9.8335C8.12885 9.57148 7.84163 9.36184 7.52055 9.21822C7.19947 9.07461 6.85173 9.00026 6.5 9H3.5C2.1215 9 1 10.1215 1 11.5V14ZM5 7C5.92826 7 6.8185 6.63125 7.47487 5.97487C8.13125 5.3185 8.5 4.42826 8.5 3.5C8.5 2.57174 8.13125 1.6815 7.47487 1.02513C6.8185 0.368749 5.92826 0 5 0C4.07174 0 3.1815 0.368749 2.52513 1.02513C1.86875 1.6815 1.5 2.57174 1.5 3.5C1.5 4.42826 1.86875 5.3185 2.52513 5.97487C3.1815 6.63125 4.07174 7 5 7ZM5 1C5.66304 1 6.29893 1.26339 6.76777 1.73223C7.23661 2.20107 7.5 2.83696 7.5 3.5C7.5 4.16304 7.23661 4.79893 6.76777 5.26777C6.29893 5.73661 5.66304 6 5 6C4.33696 6 3.70107 5.73661 3.23223 5.26777C2.76339 4.79893 2.5 4.16304 2.5 3.5C2.5 2.83696 2.76339 2.20107 3.23223 1.73223C3.70107 1.26339 4.33696 1 5 1Z"
        fill="#646464"
      />
    </svg>
  );
};

export default SubscriptionPlanSvgIcon;
