import React from "react";

const ThreeDotsSvgIcon = () => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47.01 16.32"
      width="20"
      height="7"
    >
      <path
        d="M5.09 11.79a5 5 0 01-1.48-3.67 4.83 4.83 0 011.48-3.58A4.92 4.92 0 018.64 3.1a4.76 4.76 0 013.48 1.4 4.94 4.94 0 011.4 3.62 5 5 0 01-1.44 3.67 4.7 4.7 0 01-3.44 1.44 4.92 4.92 0 01-3.55-1.44zm14.91 0a4.91 4.91 0 01-1.48-3.67A4.79 4.79 0 0120 4.54a4.92 4.92 0 013.58-1.44 4.72 4.72 0 013.47 1.4 4.91 4.91 0 011.41 3.62A5 5 0 0127 11.79a4.7 4.7 0 01-3.44 1.44A4.92 4.92 0 0120 11.79zm15 0a4.91 4.91 0 01-1.48-3.67A4.79 4.79 0 0135 4.54a4.9 4.9 0 013.52-1.44A4.74 4.74 0 0142 4.5a4.91 4.91 0 011.4 3.62 5 5 0 01-1.4 3.67 4.72 4.72 0 01-3.44 1.44A4.9 4.9 0 0135 11.79z"
        fill="#9cb2bd"
      ></path>
    </svg>
  );
};

export default ThreeDotsSvgIcon;
