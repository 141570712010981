import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  FormLabel,
  MenuItem,
  Select,
  IconButton,
  Typography,
  List,
  ListItem,
  Tabs,
  Tab,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { useNavigate, useParams } from "react-router-dom";
import { getHelperPublicProfile } from "../../Services/ProfileServices/ProfileService";
import PageLoader from "../../Components/Common/Loader/PageLoader";
import moment from "moment";
import { toastMessage } from "../../Utils/toastMessages";
import { useTranslation } from "react-i18next";
import { addApplicantToFavorites } from "../../Services/JobsServices/JobServices";
import { successType } from "../../Constant/Constant";
import NoDataFound from "../../Components/Common/NoDataFound";

const expectedDuties = {
  care: ["Child (4-12)"],
  cooking: ["Indian", "Vegetarian", "Chinese"],
  householdChore: ["Cleaning", "Car Washing"],
};
const ViewFullProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showContactNumber, setShowContactNumber] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [helperDetails, setHelperDetails] = useState(null);
  useEffect(() => {
    handleGetHelperPublicProfile();
  }, []);
  const handleGetHelperPublicProfile = () => {
    setLoader(true);
    getHelperPublicProfile(id)
      .then((res) => {
        setHelperDetails(res?.data);
        setIsFavourite(res?.data?.isFavorite);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toastMessage(err.response.data.message);
        } else {
          toastMessage(t("failure_message"));
        }
      })
      .finally((res) => setLoader(false));
  };
  const saveApplicants = () => {
    setLoader(true);
    addApplicantToFavorites(id)
      .then((res) => {
        setLoader(false);
        setIsFavourite(true);
        toastMessage(t("added_favorite_success"), successType);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.message) {
          toastMessage(err.response.data.message);
        } else {
          toastMessage(t("failure_message"));
        }
        console.log(err, "error!!!");
      });
  };
  return (
    <div>
      {loader && <PageLoader />}
      <div className="card-title m-0">
    <h3 className="fw-bold m-0">Profile Details</h3>
  </div>
      <Grid container spacing={3} m={0} mt={4} className="customGridW">
    
        <Grid md={9}>
          
          <Box className="applicantDetails">
            
            <Box className="employerHeader">
              <Box className="primary">
                <Box className="helperImg">
                  <img
                    src={
                      helperDetails?.profilePicURL
                        ? helperDetails?.profilePicURL
                        : "/demo-user.png"
                    }
                    alt="userimg"
                  />
                </Box>
                {/* <Box className="helperContent">
                  {helperDetails?.aboutYou?.fullName && (
                    <Typography variant="h5">
                      {helperDetails?.aboutYou?.fullName}
                    </Typography>
                  )}
                  {helperDetails?.aboutYou?.location && (
                    <Box className="locationDate">
                      <Box className="location">
                        {helperDetails?.aboutYou?.location}
                      </Box>
                      <Box className="date">{`${moment().diff(
                        helperDetails?.aboutYou?.dob,
                        "years"
                      )} years`}</Box>
                    </Box>
                  )}
                </Box> */}
                <div className="d-flex gap-7 align-items-center">
    {/* <div className="symbol symbol-circle symbol-100px">
      <img src="assets/media/avatars/300-6.jpg" alt="image" />
    </div> */}

    <div className="d-flex flex-column gap-2">
      <h3 className="mb-0">Emma Smith</h3>

      <div className="d-flex align-items-center gap-2">
        <i className="ki-duotone ki-sms fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <a href="#" className="text-muted text-hover-primary">smith@kpmg.com</a>
      </div>

      <div className="d-flex align-items-center gap-2">
        <i className="ki-duotone ki-phone fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <a href="#" className="text-muted text-hover-primary">+6141 234 567</a>
      </div>
    </div>
  </div>

              </Box>
              <Box className="secondary"></Box>
            </Box>

            <Box className="highlightsApplicant" mt={4}>
    

              {helperDetails?.aboutYou?.currentEmploymentStatus && (
                <>
                  <Typography variant="h5">
                    {t("current_employment_status")}
                  </Typography>
                  <Typography variant="body1">
                    {helperDetails?.aboutYou?.fullName} current employment is{" "}
                    {helperDetails?.aboutYou?.currentEmploymentStatus}
                  </Typography>
                </>
              )}
              {helperDetails?.aboutYou?.religion && (
                <>
                  <Typography variant="h5">{t("religion")}</Typography>
                  <Typography variant="body1">
                    {`${helperDetails?.aboutYou?.fullName}'s religion is ${helperDetails?.aboutYou?.religion}`}
                  </Typography>
                </>
              )}
              <Box className="expectedDuties">
                {/* <Typography variant="h5">{t("expected_duties")}</Typography> */}
                {/* {Object.keys(expectedDuties).map((skill) => (
                  <Box className="dutiesList">
                    <Typography variant="body1">{skill}</Typography>
                    <List>
                      {expectedDuties[skill] &&
                        expectedDuties[skill].map((opt) => (
                          <ListItem>{opt}</ListItem>
                        ))}
                    </List>
                  </Box>
                ))} */}
                {helperDetails?.aboutYou?.skills?.length && (
                  <Box className="dutiesList">
                    {helperDetails?.aboutYou?.skills?.map((curSkill, idx) => (
                      <ListItem key={idx}>{curSkill}</ListItem>
                    ))}
                  </Box>
                )}
              </Box>

              {helperDetails?.languages?.native && (
                <>
                  <Typography variant="h5">{t("spoken_languages")}</Typography>
                  <Typography variant="body1">
                    {`${helperDetails?.aboutYou?.fullName}'s language is ${helperDetails?.languages?.native}`}
                  </Typography>
                </>
              )}
            </Box>
            <div className="card-body pt-5">
  {/* <div className="d-flex gap-7 align-items-center">
    <div className="symbol symbol-circle symbol-100px">
      <img src="assets/media/avatars/300-6.jpg" alt="image" />
    </div>

    <div className="d-flex flex-column gap-2">
      <h3 className="mb-0">Emma Smith</h3>

      <div className="d-flex align-items-center gap-2">
        <i className="ki-duotone ki-sms fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <a href="#" className="text-muted text-hover-primary">smith@kpmg.com</a>
      </div>

      <div className="d-flex align-items-center gap-2">
        <i className="ki-duotone ki-phone fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <a href="#" className="text-muted text-hover-primary">+6141 234 567</a>
      </div>
    </div>
  </div> */}

  <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2">
    <li className="nav-item">
      <a className="nav-link text-active-primary d-flex align-items-center pb-4 active" >
     <Button
              className="green-btn small mb-2"
              mt={1}
              onClick={() => navigate(`/employer/chat/${id}`)}
            >
              {t("send_message")}
            </Button>
      </a>
    </li>

    <li className="nav-item">
      <a className="nav-link text-active-primary d-flex align-items-center pb-4" >
         <Button
              className="green-btn small mb-2 bottomDetails button.green-btn d-flex subcloseButton"
              mt={1}
              onClick={() => setShowContactNumber(!showContactNumber)}
            >
              {showContactNumber ? (
                <>
                  {helperDetails?.phoneNumber}
                  <IconButton
                    sx={{ visibility: "visible" }}
                    onClick={() => setShowContactNumber(false)}
                    className="crossButton"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </>
              ) : (
                t("get_phone_number")
              )}
            </Button>
      </a>
    </li>

    <li className="nav-item">
      <a className="nav-link text-active-primary d-flex align-items-center pb-4" >
      <Button
              className="green-btn small mb-2"
              mt={1}
              disabled={isFavourite}
              onClick={saveApplicants}
            >
              {isFavourite
                ? t("already_added_to_favourite")
                : t("add_to_favourites")}
            </Button>
      </a>
    </li>
  </ul>

  <div className="tab-content">
    <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">
      <div className="d-flex flex-column gap-5 mt-7">
        {/* <div className="d-flex flex-column gap-1">
          <div className="fw-bold text-muted">Company Name</div>
          <div className="fw-bold fs-5">Keenthemes Inc</div>
        </div>

        <div className="d-flex flex-column gap-1">
          <div className="fw-bold text-muted">City</div>
          <div className="fw-bold fs-5">Melbourne</div>
        </div>

        <div className="d-flex flex-column gap-1">
          <div className="fw-bold text-muted">Country</div>
          <div className="fw-bold fs-5">Australia</div>
        </div> */}

        <div className="d-flex flex-column gap-1">
          <div className="fw-bold text-muted">Description</div>
          <p>
            Emma Smith joined the team in September 2019 as a junior associate. She soon showcased her expertise and experience in knowledge and skill in the field, which was very valuable to the company. She was promptly promoted to senior associate in July 2020.
            <br />
            <br />Emma Smith now heads a team of 5 associates and leads the company's sales growth by 7%.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
          </Box>


        </Grid>
      </Grid>
    </div>
  );
};

export default ViewFullProfile;
