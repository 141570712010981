import React from "react";

export default function JobOfferSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path d="M11.127 14.291H15.873V15.873H11.127V14.291Z" fill="white" />
      <path
        d="M21.9047 15.8729H17.4551V16.6639C17.4551 17.101 17.1012 17.4549 16.6641 17.4549H10.3359C9.89882 17.4549 9.54492 17.101 9.54492 16.6639V15.8729H5.09525C4.07249 15.8729 3.16798 15.2209 2.84415 14.2507L0 5.7168V22.992C0 24.3005 1.06458 25.3651 2.37305 25.3651H24.627C25.9354 25.3651 27 24.3005 27 22.992V5.71741L24.1556 14.2507C23.832 15.2209 22.9275 15.8729 21.9047 15.8729Z"
        fill="white"
      />
      <path
        d="M16.6644 1.63477H10.3363C9.02779 1.63477 7.96322 2.69934 7.96322 4.00781V4.79883H1.36133L4.34473 13.7503C4.45288 14.0739 4.75487 14.291 5.09558 14.291H9.54525V13.5C9.54525 13.0629 9.89915 12.709 10.3363 12.709H16.6644C17.1015 12.709 17.4554 13.0629 17.4554 13.5V14.291H21.9051C22.2458 14.291 22.5478 14.0739 22.6559 13.7503L25.6395 4.79883H19.0374V4.00781C19.0374 2.69934 17.9729 1.63477 16.6644 1.63477ZM9.54525 4.79883V4.00781C9.54525 3.57131 9.89977 3.2168 10.3363 3.2168H16.6644C17.1009 3.2168 17.4554 3.57131 17.4554 4.00781V4.79883H9.54525Z"
        fill="white"
      />
    </svg>
  );
}
