import React from "react";

export default function ChatIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="chat customSvG"
    >
      <path
        d="M10.5049 5.88366H3.51478C3.32939 5.88366 3.15159 5.96097 3.0205 6.09858C2.88941 6.2362 2.81576 6.42284 2.81576 6.61746C2.81576 6.81207 2.88941 6.99871 3.0205 7.13633C3.15159 7.27394 3.32939 7.35125 3.51478 7.35125H10.5049C10.6903 7.35125 10.8681 7.27394 10.9992 7.13633C11.1303 6.99871 11.2039 6.81207 11.2039 6.61746C11.2039 6.42284 11.1303 6.2362 10.9992 6.09858C10.8681 5.96097 10.6903 5.88366 10.5049 5.88366ZM7.70887 8.81885H3.51478C3.32939 8.81885 3.15159 8.89616 3.0205 9.03377C2.88941 9.17139 2.81576 9.35803 2.81576 9.55265C2.81576 9.74726 2.88941 9.93391 3.0205 10.0715C3.15159 10.2091 3.32939 10.2864 3.51478 10.2864H7.70887C7.89426 10.2864 8.07205 10.2091 8.20314 10.0715C8.33424 9.93391 8.40788 9.74726 8.40788 9.55265C8.40788 9.35803 8.33424 9.17139 8.20314 9.03377C8.07205 8.89616 7.89426 8.81885 7.70887 8.81885ZM7.00985 0.74707C6.09189 0.74707 5.18292 0.936873 4.33484 1.30564C3.48675 1.67441 2.71617 2.21492 2.06707 2.89632C0.756163 4.27245 0.0197027 6.1389 0.0197027 8.08505C0.0135918 9.77949 0.572478 11.4228 1.59948 12.73L0.201447 14.1976C0.104453 14.3008 0.0387481 14.4318 0.0126231 14.5742C-0.013502 14.7165 0.00112324 14.8638 0.0546534 14.9974C0.112712 15.1295 0.206832 15.2404 0.325065 15.3162C0.443298 15.392 0.580313 15.4292 0.718718 15.423H7.00985C8.86375 15.423 10.6417 14.6499 11.9526 13.2738C13.2635 11.8976 14 10.0312 14 8.08505C14 6.1389 13.2635 4.27245 11.9526 2.89632C10.6417 1.52018 8.86375 0.74707 7.00985 0.74707ZM7.00985 13.9554H2.40334L3.05343 13.273C3.18362 13.1355 3.2567 12.9495 3.2567 12.7557C3.2567 12.5618 3.18362 12.3758 3.05343 12.2383C2.13813 11.2786 1.56814 10.0153 1.44058 8.66387C1.31303 7.3124 1.63578 5.95631 2.35387 4.82664C3.07196 3.69697 4.14095 2.86361 5.37871 2.46854C6.61647 2.07347 7.94643 2.14114 9.142 2.66002C10.3376 3.17889 11.3248 4.11687 11.9354 5.31415C12.5461 6.51143 12.7424 7.89393 12.4909 9.22611C12.2395 10.5583 11.5558 11.7577 10.5563 12.6201C9.5569 13.4825 8.30356 13.9544 7.00985 13.9554Z"
        fill="#646464"
      />
    </svg>
  );
}
