import React from "react";

export default function LogoutIcon() {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logout customSvG"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.14692 16.6136V17.5649C8.14692 18.3277 7.79034 18.9863 7.17072 19.3677C6.8726 19.5512 6.53987 19.6535 6.1949 19.6537C5.84961 19.6539 5.51698 19.5513 5.21866 19.3677L1.2516 16.9256C0.6319 16.5442 0.275391 15.8858 0.275391 15.1228V2.6476C0.275391 1.49969 1.15121 0.565918 2.2278 0.565918H10.9647C12.0414 0.565918 12.9173 1.49962 12.9173 2.6476V5.27735C12.9173 5.6546 12.63 5.9609 12.2762 5.9609C11.9222 5.9609 11.6352 5.65463 11.6352 5.27735V2.6476C11.6352 2.25343 11.3344 1.93271 10.9647 1.93271H3.3089L7.17072 4.31036C7.79013 4.69175 8.14692 5.35012 8.14692 6.11278V15.2467H10.9647C11.3344 15.2467 11.6352 14.9261 11.6352 14.5319V12.2266C11.6352 11.8491 11.9221 11.5431 12.2762 11.5431C12.6301 11.5431 12.9173 11.8492 12.9173 12.2266V14.5319C12.9173 15.6799 12.0414 16.6136 10.9647 16.6136H8.14692ZM14.087 9.27314L13.381 10.0259C13.1307 10.2928 13.1308 10.7255 13.381 10.9924C13.5015 11.1209 13.6639 11.1924 13.8343 11.1924C14.0048 11.1924 14.167 11.1211 14.2877 10.9924L16.0877 9.07287C16.3379 8.80602 16.338 8.37356 16.0877 8.1067L14.2877 6.18749C14.0373 5.92056 13.6315 5.92067 13.381 6.18745C13.1308 6.45408 13.1309 6.88695 13.381 7.15366L14.087 7.90621H9.35754C9.00337 7.90621 8.71659 8.21221 8.71659 8.58979C8.71659 8.96737 9.00341 9.27318 9.35754 9.27318H14.087V9.27314Z"
        fill="#646464"
      />
    </svg>
  );
}
