import React from "react";

const AgencySvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
    >
      <path
        d="M0.142578 2.79129C0.142578 2.22297 0.368343 1.67793 0.770206 1.27607C1.17207 0.874202 1.71711 0.648438 2.28544 0.648438H7.14258C7.7109 0.648438 8.25594 0.874202 8.65781 1.27607C9.05967 1.67793 9.28543 2.22297 9.28543 2.79129V7.64844C9.28543 8.21676 9.05967 8.7618 8.65781 9.16367C8.25594 9.56553 7.7109 9.7913 7.14258 9.7913H2.28544C1.71711 9.7913 1.17207 9.56553 0.770206 9.16367C0.368343 8.7618 0.142578 8.21676 0.142578 7.64844V2.79129ZM12.2866 9.03872L9.85686 7.36101V3.07701L12.2866 1.39929C12.9494 0.941009 13.8546 1.41644 13.8546 2.22215V8.21644C13.8546 9.02215 12.95 9.49644 12.2866 9.03872Z"
        fill="#646464"
      />
    </svg>
  );
};

export default AgencySvgIcon;
